
/*================ COLORS ================*/

$color-primary: #55b7ff;
$color-secondary: #55b7ff;
$color-background: #01A9C4;
$color-gold: #FED766;

$color-ultralight: #f9f9f9;
$color-light-grey: #e4e4e4;
$color-grey: #aaa;
$color-medium-grey: #676767;
$color-dark-grey: #222;
$color-black: #010101;

// Semantic applications of the color palette
$color-text: #fff;
$color-hr: $color-light-grey;

/*================ TYPOGRAPHY ================*/

// Font stacks
$font-body: sans-serif;
$font-headings: sans-serif;

$line-height: 1.5;
$line-height-slim: 1.25;


/*================ PADDING ================*/
$gutter: 20px;