@keyframes working-rotate {
  0% {
    transition-timing-function: ease-in;
    transform: rotate(0deg);
  }
  25% {
    transition-timing-function: linear;
    transform: rotate(-36deg);
  }
  100% {
    transition-timing-function: ease-out;
    transform: rotate(360deg);
  }
}
@keyframes working-rotate-reverse {
  0% {
    transition-timing-function: ease-in;
    transform: rotate(0deg);
  }
  25% {
    transition-timing-function: linear;
    transform: rotate(36deg);
  }
  100% {
    transition-timing-function: ease-out;
    transform: rotate(-360deg);
  }
}

.working .gear {
  animation: working-rotate 3.5s 1;
  &.reverse {
    animation: working-rotate-reverse 3.5s 1;
  }
}
.clear-working {
  transition: none !important;
  transform: rotate(0deg) !important;
  animation: none !important;
}

.gear {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg version='1.0' viewBox='0 0 110 110' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55 24.818c-16.642 0-30.182 13.54-30.182 30.181S38.359 85.18 55 85.18s30.181-13.54 30.181-30.181S71.642 24.818 55 24.818zm0 56.156c-14.347 0-25.976-11.629-25.976-25.975S40.652 29.024 55 29.024c14.345 0 25.975 11.629 25.975 25.975S69.345 80.974 55 80.974zm54.773-21.113c.141-1.603.227-3.223.227-4.862s-.086-3.259-.227-4.862l-10.918-1.293a43.973 43.973 0 00-2.794-10.446l8.815-6.581a54.783 54.783 0 00-4.873-8.417l-10.106 4.344a44.461 44.461 0 00-7.642-7.642l4.342-10.107a54.932 54.932 0 00-8.416-4.872l-6.582 8.814a43.929 43.929 0 00-10.445-2.793L59.861.225a55.824 55.824 0 00-4.862-.226c-1.642 0-3.259.086-4.864.226l-1.291 10.919a43.911 43.911 0 00-10.446 2.793l-6.581-8.814A54.907 54.907 0 0023.4 9.995l4.342 10.107a44.49 44.49 0 00-7.641 7.642L9.995 23.4a54.99 54.99 0 00-4.872 8.417l8.814 6.581a43.924 43.924 0 00-2.793 10.446L.225 50.136C.085 51.74 0 53.36 0 54.999s.085 3.259.226 4.863l10.919 1.292A44.031 44.031 0 0013.938 71.6l-8.814 6.581a54.942 54.942 0 004.872 8.417l10.107-4.343a44.52 44.52 0 007.641 7.641l-4.342 10.106a54.912 54.912 0 008.417 4.873l6.581-8.814a44.019 44.019 0 0010.446 2.793l1.291 10.919a55.87 55.87 0 004.864.226c1.639 0 3.259-.086 4.862-.226l1.293-10.919a44.023 44.023 0 0010.445-2.793l6.582 8.814a54.974 54.974 0 008.416-4.871l-4.342-10.108a44.562 44.562 0 007.642-7.641l10.106 4.343a54.912 54.912 0 004.873-8.417L96.061 71.6a43.986 43.986 0 002.794-10.446l10.918-1.293zM55 87.314c-17.819 0-32.315-14.497-32.315-32.314S37.181 22.686 55 22.686c17.817 0 32.314 14.497 32.314 32.314S72.817 87.314 55 87.314z' fill='%23350054'/%3E%3C/svg%3E");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.gear1,
.gear2,
.gear3 {
  width: 50vw;
  height: 50vw;
  position: absolute;
}
.gear1 {
  left: -12vw;
  top: -12vw;
}
.gear2 {
  left: 25vw;
  top: 16vw;
}
.gear3 {
  left: 62vw;
  top: -12vw;
}
.background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  transform: scale(1.4) translateX(14%) translateY(14%);
}
