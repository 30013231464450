
.field {
	margin: $gutter 0;
}


button, .btn {
	border-radius: 100px;
	background-color: $color-primary;
	color: #fff;
	border: none;
	padding: 10px 25px;
	margin: 5px;
	display: inline-block;
	font-weight: 700;
	box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
	&.small {
		box-shadow: none;
	}
}

.btn--secondary {
	background-color: $color-secondary;
}