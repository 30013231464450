
iframe[name='google_conversion_frame'] { 
    height: 0 !important; 
    line-height: 0 !important; 
    font-size: 0 !important; 
    position: absolute;
    z-index: -10000;
}

.cookie-bar-bar button#catapultCookie {
    background-color: #e0e0e0;
}