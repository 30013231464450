.app {
  height: 100%;
  position: relative;
  text-align: center;
}

.app__wrapper {
  position: relative;
  width: 100%;
  min-height: 70vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app__inner {
  position: relative;
  margin: auto;
  width: 800px;
  max-width: 90%;
  @media (min-width: 700px) {
    padding: 40px;
  }
}
.app__message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 132px;
  padding: 20px;
  background: white;
  color: black;
  border: 10px solid #6f00af;
  border-radius: 20px;
}
.app__randomize {
  cursor: pointer;
  background: $color-gold;
  color: black;
  &.disabled {
    cursor: not-allowed;
    background: #e0e0e0;
  }
}

.app__history {
  display: none;
}

.app__favorite {
  cursor: pointer;
  position: relative;
  background: #32292f;
  color: $color-gold;
  transition: background 0.75s ease, color 0.5s ease;
  &.small {
    padding: 10px;
    margin-right: 10px;
    background: transparent;
    color: #32292f;
  }
  &.disabled {
    cursor: not-allowed;
    background: $color-gold;
    color: $color-background;
  }
  i.a {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $color-gold;
    transition: top 0.5s ease-out, left 0.5s ease-out, transform 0.75s ease-out;
  }
}

.app__favorites {
  padding: 20px 0;
  margin: auto;
  width: 100%;
  overflow-y: auto;
  & > div {
    max-width: 95%;
    width: 480px;
    text-align: left;
    margin: 5px auto;
  }
}

.app__tweet {
  text-shadow: 3px 3px 3px #4492cc;
}
.app__title {
  margin-bottom: 0;
  font-weight: 800;
}
