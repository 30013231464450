
html {
	font-family: $font-body;
	font-size: 1.2em;
	color: $color-text;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	background: $color-background;
}

a {
	color: $color-primary;
	text-decoration: none;
	border-bottom: 2px solid rgba($color-primary, 0);
	transition: border-bottom-color 0.5s ease-in-out;

	&:hover {
		border-bottom-color: $color-primary;
	}
}

.thumb {
	border: 1px solid $color-light-grey;
	padding: 5px;
}

.light {
	color: $color-grey;
}

button {
	outline: none;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
}